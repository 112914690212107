<template>
  <div class="container">
    <div class="card mx-auto" style="border-radius: 1.5rem; width: 20rem">
      <div class="mt-5 text-center">
        <img src="../assets/logo.png" class="img-fluid" alt="..." width="50%" />
      </div>

      <div class="card-body text-start p-5 pt-3 pb-3">
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping">
            <i class="fas fa-user-astronaut"></i>
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="addon-wrapping"
            v-model="email"
          />
        </div>
        <br />
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping">
            <i class="fas fa-lock"></i>
          </span>
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            aria-label="Password"
            aria-describedby="addon-wrapping"
            v-model="password"
          />
        </div>
        <button class="mt-4 mb-4 col-12 btn btn-primary btn-block" @click="login">
          LOGIN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    login(e) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          (user) => {
            console.log(user)
            localStorage.setItem('uid', user.user.uid)
            localStorage.setItem('token', user.user.ya)
            this.$router.replace('/')
          },
          (err) => {
            alert(err.message)
          }
        )
      e.preventDefault()
    },
  },
}
</script>
<style>
.container {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-add {
  background-color: plum;
  color: #ffff;
}

.btn-cal {
  background-color: #a74afa;
  color: #ffff;
}
</style>
