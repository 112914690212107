export default {
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  apiKey: 'AIzaSyDLsqdRCywIqYd7ab8khq2c8esW7feMu_8',
  authDomain: 'sierra-badminton.firebaseapp.com',
  databaseURL: "https://sierra-badminton.firebaseio.com",
  projectId: 'sierra-badminton',
  storageBucket: 'sierra-badminton.appspot.com',
  messagingSenderId: '924656162409',
  appId: '1:924656162409:web:828656dc983d987de5f4f8',
  measurementId: 'G-2PZTWWZ854',
}
